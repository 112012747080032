import React, { useState } from 'react'
import http from '../../utils/http'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'

const Login = () => {
  const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const { setAuthenticated } = useAuth()

  const [errorMessage, setErrorMessage] = useState({
    username: '',
    password: ''
  })

  const handleLogin = async (e) => {
    e.preventDefault()
    if (username === '' && password === '') {
      setErrorMessage({
        ...errorMessage,
        username: 'Tên đăng nhập không được để trống.',
        password: 'Mật khẩu không được để trống.'
      })
      return
    }
    if (username === '') {
      setErrorMessage({
        ...errorMessage,
        username: 'Tên đăng nhập không được để trống.'
      })
      return
    }
    if (password === '') {
      setErrorMessage({
        ...errorMessage,
        password: 'Mật khẩu không được để trống.'
      })
      return
    }
    try {
      const response = await http.post('/users/sign_in', {
        username,
        password
      })
      localStorage.setItem('ACCESS_TOKEN', response.accessToken)
      const userid = response._id
      const result = await http.post('/userkiosk/getuserkiosk', {
        userid
      })
      let kioskNumber = result.data.length
      sessionStorage.setItem('kioskTotal', kioskNumber)
      const settingRes = await http.get('/settings', {})
      // save kiosk to session
      result.data.forEach(function (object) {
        --kioskNumber
        const ss = object.kioskid
        sessionStorage.setItem('kiosk' + kioskNumber, ss)
        sessionStorage.setItem(
          'kioskName' + kioskNumber,
          settingRes.data.find((kiosk) => kiosk._id === ss).kioskName
        )
        sessionStorage.setItem(
          'kioskId' + kioskNumber,
          settingRes.data.find((kiosk) => kiosk._id === ss).kioskId
        )
        console.log('Kiosk ',ss,settingRes.data.find((kiosk) => kiosk._id === ss).kioskName,settingRes.data.find((kiosk) => kiosk._id === ss).kioskId)
      })
      setAuthenticated(true)
    } catch (error) {
      toast.error(error.errors?.replace('Error: ', ''))
    }
  }
  return (
    <div
      // style={{ transform: 'translate(-132.5px, 0)' }}
      className="login-form bg-body rounded shadow-sm p-10 p-lg-15 mx-auto my-auto"
    >
      <form className="form w-100" noValidate="novalidate" id="kt_sign_in_form">
        <div className="text-center mb-10">
          <h1 className="text-dark mb-5 text-uppercase login-title">
            Quản lý TseVending
          </h1>
          <img src="/assets/images/logo512.png" alt="car" width={100} />
        </div>
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">
            Tên đăng nhập
          </label>
          <input
            className="form-control form-control-lg form-control-solid"
            type="text"
            name="email"
            autoComplete="off"
            onChange={(e) => {
              setErrorMessage({
                username: '',
                password: ''
              })
              setUsername(e.target.value)
            }}
          />
          {errorMessage && errorMessage.username && (
            <div className="my-2" style={{ color: 'red', fontStyle: 'italic' }}>
              {errorMessage.username}
            </div>
          )}
        </div>
        <div className="fv-row mb-10">
          <div className="d-flex flex-stack mb-2">
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              Mật khẩu
            </label>
          </div>
          <input
            className="form-control form-control-lg form-control-solid"
            type="password"
            name="password"
            autoComplete="off"
            onChange={(e) => {
              setErrorMessage({
                username: '',
                password: ''
              })
              setPassword(e.target.value)
            }}
          />
          {errorMessage && errorMessage.password && (
            <div className="my-2" style={{ color: 'red', fontStyle: 'italic' }}>
              {errorMessage.password}
            </div>
          )}
        </div>
        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
            onClick={handleLogin}
          >
            <span className="indicator-label">Đăng Nhập</span>
            <span className="indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          </button>
        </div>
      </form>
    </div>
  )
}

export default Login

